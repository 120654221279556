.field-value {
  padding-bottom: 10px;

  &__value-container {
    border-bottom-color: #BDBDBD;
    border-bottom-style: dashed;
    border-bottom-width: thin;
    padding-top: 8px;
    text-align: left;
  }

  &__label {
    font-size: 12px;
  }
}
