.owner-registration-requests-list {
  &__row {
    &__cell {
      margin-left: 14px;
    }
    &_not-completed {
      background-color: lightcoral;
    }
  }
}
