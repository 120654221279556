.transactions-list {
  td {
    white-space: nowrap;
  }

  table.md-table {
    overflow: visible;
  }

  th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  &__sum {
    font-weight: bold;
    background: rgb(170, 220, 194);
    padding: 1rem;
    color: rgb(250, 250, 250);
  }

  &__row{
    &--no-b2b {
      background-color: rgb(197, 219, 240);
      font-style: italic;
    }

    &--debit {
      background-color: rgb(251, 227, 163);
    }

    &--credit {
      background-color: rgb(170, 220, 194);
    }
  }
}
