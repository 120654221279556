/* Have to set height explicity on ui-view
to prevent collapsing during animation*/
.salons__deleted {
  background-color: lightcoral;
}

.salon__edit-container.ng-enter, .salon__edit-container.ng-leave {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transition:all .5s ease-in-out;
  -moz-transition:all .5s ease-in-out;
  -o-transition:all .5s ease-in-out;
  transition:all .5s ease-in-out;
}

.salon__edit-container.ng-enter {
  opacity: 0;
  -webkit-transform:scale3d(0.5, 0.5, 0.5);
  -moz-transform:scale3d(0.5, 0.5, 0.5);
  transform:scale3d(0.5, 0.5, 0.5);
}

.salon__edit-container.ng-enter-active {
  opacity: 1;
  -webkit-transform:scale3d(1, 1, 1);
  -moz-transform:scale3d(1, 1, 1);
  transform:scale3d(1, 1, 1);
}

.salon__edit-container.ng-leave {
  opacity: 1;
  -webkit-transform:translate3d(0, 0, 0);
  -moz-transform:translate3d(0, 0, 0);
  transform:translate3d(0, 0, 0);
}

.salon__edit-container.ng-leave-active {
  opacity: 0;
  -webkit-transform:translate3d(100px, 0, 0);
  -moz-transform:translate3d(100px, 0, 0);
  transform:translate3d(100px, 0, 0);
}
