.copy-to-clipboard-button {
  min-width: 36px;
  width: 36px;
  height: 36px;

  &__image {
    margin-top: 11px;
    width: 13px;
    height: 14px;
  }
}
