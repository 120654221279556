@import "~angular-material/angular-material.scss";
@import "~angular-material-data-table/dist/md-data-table";
@import "~angular-loading-bar/build/loading-bar";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"),
       local("MaterialIcons-Regular"),
       url(./fonts/font-icons.woff2) format("woff2");
}

#loading-bar {
  .bar {
    background-color: #CDDC39;
  }
  .peg {
    -moz-box-shadow: #CDDC39 1px 0 6px 1px;
    -ms-box-shadow: #CDDC39 1px 0 6px 1px;
    -webkit-box-shadow: #CDDC39 1px 0 6px 1px;
    box-shadow: #CDDC39 1px 0 6px 1px;
  }
}

.admin-body {
  max-width: 100%;
  max-height: 100%;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: #CDDC39;
  border-left-color: #CDDC39;
}

md-toast.md-error-toast-theme .md-toast-content {
  background-color: red;
}

md-toast.md-success-toast-theme .md-toast-content {
  background-color: green;
}

.country-selector{
  padding-bottom: 30px;
}

.table-button{
  min-width: 0px;
}

.md-center-tabs{
  width: 100%;
}

.md-button.md-hue-3.md-accent.md-fab.md-ink-ripple md-icon{
  color: white;
}

.angular-google-map-container { height: 400px; }

.salony-layout-content .search-field {
  padding-left: 0px;
  padding-bottom: 15px;
}

.hide_hours{
  background-color: red;
}

.salony-admin-form{
  border-radius: 7px;
  border-color: rgb(63,81,181);
  margin-bottom: 3%;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.drop {
  padding: 15px;
  border: 2px #f1f1f1 dashed;
  border-radius: 5px;
  line-height: 34px;
}

.active_drop {
  border: 4px, solid, green;
  background-color:'lightskyblue';
}

.gallery-box .progress {
  margin-bottom: 0;
}
.gallery-box .thumbnail {
  height: 188px;
  width: 188px;
  text-align: center;
}
.gallery-box .title {
  height: 22px;
  overflow: hidden;
  display: block;
}

.thumbnail > img{
  max-width: 200px;
  max-height: 150px;
}

.image_galery, .image_galery .md-container, .image_galery .md-container .md-bar {
  height: 14px;
  border-radius: 5px;
}

.delete_image {
  margin-left: 0px;
}

.change_height {
  height: 160px;
}

.payment__form-item {
  border-bottom-color: #BDBDBD;
  border-bottom-style: dashed;
  border-bottom-width: thin;
  padding-top: 8px;
  text-align: left;
  &_button{
    color: #3F51B5;
  }
  &_button:hover{
    background-color: #BDBDB3;
    opacity: 0.5;
    cursor: pointer;
  }
}

.payment_form_item:hover{
  background-color: red;
}

.payment_label {
  font-size: 12px;
}

.payment_field-content {
  padding-bottom: 10px;
}

.finished {
  background-color:#69F0AE;
}

.failed {
  background-color:#FF0000;
}

.refunded {
  background-color: #d48900;
}


.payment_gateway_response {
  background-color:#F2FCE2;
  border-color: #BDBDBD;
  border-style: solid;
  border-width: thin;
  padding-left: 20px;
}

.bad_request_header{
  text-align: center;
}
.image-preview {
  max-height: 100px;
  max-width: 100px;
}
