.breadcrumbs {
  &__element {
    opacity: 0.7;
    color: #fff;

    &:last-child {
      opacity: 1;
    }
  }
}
