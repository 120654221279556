.md-tab {
  overflow: visible;
}

.state-tab {
  position: relative;

  &--with-warning {
    background: yellow;
  }

  &:hover {
    .state-tab__warning {
      display: block;
    }
  }

  &__warning {
    display: none;
    font-size: 8px;
    position: absolute;
    top: -6px;
    left: 100%;
    border: 1px solid;
    background: white;
    z-index: 2;
    border-radius: 7px;
    padding: 5px;
  }
}
