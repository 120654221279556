.users-credits {
  &__content {
    padding-bottom: 10px;
    border-bottom-color: #BDBDBD;
    border-bottom-style: dashed;
    border-bottom-width: thin;
    padding-top: 8px;
  }

  &__label {
    font-size: 12px
  }

  &__item {
    padding-right: 8px;
    text-align: left;
    &_content {
      padding-top: 8px;
    }
  }
}
