.dndDraggingSource {
  display: none;
}

.image-gallery {
  margin: 50px 0;

  &__item {
    img {
      max-height: 240px;
      max-width: 240px;
    }
  }
}
