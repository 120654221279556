.service-duplicate {
  &__title {
    text-align: center;
  }

  &__salon_select {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    min-width: 300px;
  }

  &__checkbox {
    margin-left: 15px;
    display: block;
  }

  &__submit {
    margin: auto;
    display: block;
    margin-bottom: 15px;
  }
}
