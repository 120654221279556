.csv-table {
  max-height: 400px;

  table, td, th {
    white-space: nowrap;
    border: 1px solid black;
  }

  th {
    position: sticky;
    top: 0;
    background: white;
  }
}
