@media screen {
  #printSection {
      display: none;
  }
}

@media print {
  @page {
    size: landscape;
    margin: 0;
  }

  body {
    height: 100%;
    width: 100%;
  }

  #main {
    display:none;
  }

  #printSection {
    display: inline;
  }

  #printSection {
    position:absolute;
    left:0;
    top:0;
  }

  .payments-list {
    a {
      color: #000 !important;
      text-decoration: none !important;
    }

    a[href]:after {
      content: none !important;
    }

  }
}

.payments-list {
  &__header {
    text-align: center;
  }

  &__tr {
    &_canceled > td {
      color: #f00 !important;
    }
  }

  &__thead-row {
    height: 30px !important;
  }

  &__th {
    color: #000 !important;
    padding: 0 10px !important;
  }

  &__td {
    padding: 5px 10px !important;
    white-space: nowrap;
  }

  &__tfoot tr:first-child td {
    border-top: 3px #000 solid !important;
  }
}
