.appointments-list {
  &__title {
    margin-left: 25px
  }

  &__row {
    &_canceled, &_expired {
      background-color: lightcoral;
    }

    &_waiting_for_cash_payment {
      background-color: #6fd400;
    }

    &_completed, &_paid {
      background-color: aquamarine;
    }
  }
}
