.services-table {
  margin-top: 10px;

  .dndDraggingSource {
    display: none;
  }
}

.services-category-toolbar {
  background-color: rgb(250, 250, 250);
  color: rgb(33, 33, 33);
  border-bottom-color: rgb(221, 221, 221);
  border-bottom-style: solid;
  border-bottom-width: thin;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  font-size: 20px;
  min-height: 48px;
  width: 100%;
}

.services-category-toolbar-placeholder {
  @extend .services-category-toolbar;
  background-color: rgba(255, 193, 7, 0.5);
}

.services-dropdown {
  &__content {
    &.ng-hide {
      opacity: 0;
    }

    &.ng-hide-add {
      transition: all linear 0.5s;
    }

    &.ng-hide-remove {
      transition: all linear 0.5s;
    }
  }
}
