.services-list-item {
  &__warning {
    background-color: rgb(255,160,122);
  }

  &__warning_hint {
    display: inline-block;
    font-size: 30px;
    color: rgb(221,44,0);
  }
}

tbody[dnd-list] {
  position: relative;

  & > tr {
    position: relative;
  }

  .handler {
    cursor: ns-resize;
  }

  .dndDraggingSource {
    display: none;
  }

  .dndPlaceholder {
    background-color: rgba(255, 193, 7, 0.5);
  }
}
