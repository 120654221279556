.payment-settings {
  &__title {
    color: rgba(0,0,0,0.54);
  }

  &__checkbox {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(0,0,0,0.12);

    &__type_image {
      margin-right: 10px;
    }
  }
}
