.admin-menu {
  padding: 0;

  &__item {
    .md-button {
      width: 100%;
      text-align: left;
      margin: 0;
      padding: 0 10px;
      border-radius: 0;
    }

    &.active {
      background-color: rgba(158,158,158,0.2);
    }
  }
}
